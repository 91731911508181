.setting-menu img ,.admin-cards img {
width: 40px;
}

 .setting-menu {
	margin-top: 5rem;
}
.link-black {
    color: black;
}
